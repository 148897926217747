import { AddCircleOutline } from "@mui/icons-material";
import { Masonry } from "@mui/lab";
import { Alert, Backdrop, Box, Breadcrumbs, Button, CircularProgress, CssBaseline, Paper, TextField, Typography, styled } from "@mui/material";
import { DroneInfo } from "adoms-common-lib/build/model/FlightInformation/DroneInfo";
import React, { useEffect, useState } from "react";
import { DroneCardView } from "../../../components/molecule/DroneCardView";
import DroneCreateDialog from "../../../components/organisms/DroneCreateDialog";
import OperationMenuBar from "../../../components/organisms/OperationMenuBar";
import { APIConnector } from "../../../connector/APIConnector";
import DroneUpdateDialog from "../../../components/organisms/DroneUpdateDialog";
import { IncrementalSearchUtil } from "../../../common/IncrementalSearchUtil";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { NewCreateButton } from "../../../components/atoms/newCreateButton";
import { havePrivilege } from "../../../common/PrivilegeUtil";
import { FunctionPrivilege } from "adoms-common-lib";
import { useUserInfoContext } from "../../../common/UserContext";

const drawerWidth = 260;

const RootDiv = styled("div")(({ theme }) => ({
    display: 'flex',
    backgroundColor: theme.palette.background.default
}));

const DrawerHeaderDiv = styled("div")(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

// isOpen true　メニューが開かれている
// isOpen false メニューが閉じている
const ContentMain = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isOpen',
})<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    ...(isOpen && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

const BackdropStyle = styled(Backdrop)(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    color: '#fff',
    padding: '12px',
    textAlign: 'center',
    height: '100%',
    alignItems: 'center',
}));

// Paperのスタイル
const PaperForAlert = styled(Paper)(({ theme }) => ({
    padding: '12px',
    textAlign: 'center',
    height: '100%',
    marginTop: theme.spacing(2),
}));

export default function DroneListView() {
    const navigate = useNavigate();
    const userInfoContext = useUserInfoContext();

    const [isMenuBarOpen, setMenuBarOpen] = useState(false);
    const [isDroneCreateDialogOpen, setDroneCreateDialogOpen] = useState<boolean>(false);
    const [isDroneUpdateDialogOpen, setDroneUpdateDialogOpen] = useState<boolean>(false);
    const [isDisplayLoadingMark, setDisplayLoadingMark] = React.useState(false);
    const [isEditDroneList, setEditDroneList] = React.useState(false);
    const [updateDroneData, setUpdateDroneData] = useState<DroneInfo>();
    const [allDroneList, setAllDroneList] = useState<DroneInfo[]>([]);
    const [displayDroneList, setDisplayDroneList] = React.useState<DroneInfo[]>([]);
    const [searchKeyword, setSearchKeyword] = useState<string>();
    const [searchResult, setSearchResult] = useState<number[]>();
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [isDisplayLoadingMarkForUserInfo, setDisplayLoadingMarkForUserInfo] = React.useState(false);
    const [userErrorMessage, setUserErrorMessage] = React.useState<string | undefined>(undefined);
    const [canEditDroneUser, setEditDronetUser] = React.useState(false);

    /**
    * userInfoを取得する
    */
    useEffect(() => {
        if (userInfoContext.isError) {
            setUserErrorMessage("ユーザー情報が取得できませんでした。一部の機能が使えないことがあります。");
            setDisplayLoadingMarkForUserInfo(false);
        } else {
            setUserErrorMessage(undefined);
            if (typeof userInfoContext.userInfo === "undefined") {
                setDisplayLoadingMarkForUserInfo(true);
            } else {
                setDisplayLoadingMarkForUserInfo(false);
            }
        }
        setEditDronetUser(havePrivilege(FunctionPrivilege.PrivilegeFunctionEditDrone, userInfoContext.userInfo));
    }, [userInfoContext]);

    useEffect(() => {
        //ドローン情報を取得
        const fetchDroneData = async () => {
            setEditDroneList(false);
            setDisplayLoadingMark(true);
            let c: APIConnector = APIConnector.instance;
            await c.getDroneList().then(async (droneList: DroneInfo[]) => {
                setAllDroneList(droneList);
                if (searchResult) {
                    let displayDroneList: DroneInfo[] = new Array();
                    droneList.forEach((drone, index) => {
                        if (searchResult.includes(index)) {
                            displayDroneList.push(drone);
                        }
                    });
                    setDisplayDroneList(displayDroneList);
                } else {
                    setDisplayDroneList(droneList);
                }
                setDisplayLoadingMark(false);
            }).catch((error) => {
                console.log(error);
                if (axios.isAxiosError(error)
                    && typeof error.response !== "undefined"
                    && error.response.status === 403) {
                    handleToForbidden403Page();
                } else {
                    setErrorMessage("機体情報を取得できませんでした。");
                };
                setDisplayLoadingMark(false);
            });
        }
        fetchDroneData();
    }, [isEditDroneList, searchResult]);

    // メニューバーが開かれたときのハンドラ
    const handleDrawerOpen = () => {
        setMenuBarOpen(true);
    };

    // メニューバーが閉じられた時のハンドラ
    const handleDrawerClose = () => {
        setMenuBarOpen(false);
    };

    // ドローン情報を新規作成するダイアログを開く
    const handleDroneCreateDialogOpen = () => {
        setDroneCreateDialogOpen(true);
    };

    // ドローン情報を新規作成するダイアログをクローズする
    const handleDroneCreateDialogClose = () => {
        setDroneCreateDialogOpen(false);
    };

    // ドローン情報を更新するダイアログを開く
    const handleDroneUpdateDialogOpen = (drone: DroneInfo) => {
        setUpdateDroneData(drone);
        setDroneUpdateDialogOpen(true);
    };

    // ドローン情報を更新するダイアログを閉じる
    const handleDroneUpdateDialogClose = () => {
        setDroneUpdateDialogOpen(false);
    };

    /**
    * 403ページに遷移させる
    * @param navigate 
    */
    const handleToForbidden403Page = () => {
        navigate(
            "/forbidden403Page"
        );
    };

    // 検索する言葉がある情報を取得する
    const searchHandler = (keyword: string) => {
        if (allDroneList != null) {
            const IncrementalSearchInstance = new IncrementalSearchUtil;
            let result = IncrementalSearchInstance.incrementalSearch(allDroneList, keyword);
            setSearchResult(result);
        };
    };

    // ドローンのCardを作成
    const displayCardBody = () => {
        if (allDroneList.length !== 0) {
            if (displayDroneList.length !== 0) {
                return (
                    <Masonry columns={{ xs: 1, sm: 2, md: 3, lg: 4 }} spacing={2} aria-labelledby="機体情報">
                        {displayDroneList.map((droneData, index) => {
                            return <DroneCardView
                                key={index}
                                droneData={droneData}
                                allDroneList={displayDroneList}
                                handleDroneUpdateDialogOpen={handleDroneUpdateDialogOpen}
                                canEditDronetUser={canEditDroneUser}
                                setEditDroneList={setEditDroneList}
                                setDisplayLoadingMark={setDisplayLoadingMark} />
                        })}
                    </Masonry>
                );
            } else {
                return (
                    <PaperForAlert elevation={1}>
                        <Alert severity="info">検索結果はありません</Alert>
                    </PaperForAlert>
                );
            };
        } else if (typeof errorMessage !== "undefined") {
            return (
                <PaperForAlert elevation={1}>
                    <Alert severity="error">{errorMessage}</Alert>
                </PaperForAlert>
            );
        } else {
            return (
                <PaperForAlert elevation={1}>
                    <Alert severity="info">機体情報が登録されていません</Alert>
                </PaperForAlert>
            );
        };
    };


    return (
        <RootDiv>
            <CssBaseline />

            {/**メニューバーを表示する */}
            <OperationMenuBar
                onChangeDrawerOpen={handleDrawerOpen}
                onChangeDrawerClose={handleDrawerClose}
                open={isMenuBarOpen}
                title="機体管理"
            />
            {/** コンテンツ部分 */}
            <ContentMain
                isOpen={isMenuBarOpen}
                component="main"
            >
                <DrawerHeaderDiv />

                <Breadcrumbs aria-label="breadcrumb" aria-labelledby="現在位置">
                    <Typography color="textPrimary" aria-current="page" variant="h5">
                        機体一覧
                    </Typography>
                </Breadcrumbs>

                {/** 新規作成ボタン */}
                {canEditDroneUser ?
                    <NewCreateButton
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={handleDroneCreateDialogOpen}
                        startIcon={<AddCircleOutline />}
                        style={{ zIndex: 100 }}
                        aria-labelledby="新規作成">
                        新規作成
                    </NewCreateButton>
                    : undefined
                }

                <Box mt={3}>
                    {/** 検索キーワード */}
                    <TextField
                        size="medium"
                        variant="filled"
                        type="text"
                        label="検索文字"
                        value={searchKeyword}
                        aria-labelledby="絞りこみキーワード"
                        onChange={(e) => {
                            setSearchKeyword(e.target.value);
                            searchHandler(e.target.value);
                        }}
                    />
                </Box>
                {
                    userErrorMessage ? (
                        <PaperForAlert sx={{ height: '19%', }} elevation={1}>
                            <Alert severity="error">{userErrorMessage}</Alert>
                        </PaperForAlert>
                    ) : (null)
                }
                <Box mt={6}>
                    {displayCardBody()}

                    {/**機体情報を新規作成するダイアログ */}
                    <DroneCreateDialog
                        isOpen={isDroneCreateDialogOpen}
                        closeHandler={handleDroneCreateDialogClose}
                        allDroneList={allDroneList}
                        setEditDroneList={setEditDroneList}
                        setDisplayLoadingMark={setDisplayLoadingMark} />

                    {/** 機体情報を更新するダイアログ */}
                    <DroneUpdateDialog
                        isOpen={isDroneUpdateDialogOpen}
                        closeHandler={handleDroneUpdateDialogClose}
                        droneData={updateDroneData}
                        allDroneList={allDroneList}
                        setEditDroneList={setEditDroneList}
                        setDisplayLoadingMark={setDisplayLoadingMark} />
                </Box>
            </ContentMain>

            <BackdropStyle
                open={isDisplayLoadingMark || isDisplayLoadingMarkForUserInfo}>
                <CircularProgress color="inherit" />
            </BackdropStyle>
        </RootDiv>
    );
};